<template>
	<div class="form">
		<el-form ref="form" :model="form">
			<el-row :gutter="10">
				<el-col :span="$store.state.platform == 'pc' ? 10 : 8">
					<el-form-item :label="$t('tip.firstName')">
						<el-input v-model="form.firstName"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="$store.state.platform == 'pc' ? 10 : 8">
					<el-form-item :label="$t('tip.secondName')">
						<el-input v-model="form.secondName"></el-input>
					</el-form-item>
				</el-col>
				<el-col v-if="false" :span="$store.state.platform == 'pc' ? 4 : 8">
					<el-form-item :label="$t('tip.default')">
						<el-select v-model="form.defaultFlag" no-data-text="Sin datos">
							<el-option :label="$t('tip.yes')" :value="1"></el-option>
							<el-option :label="$t('tip.no')" :value="0"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="8">
					<el-form-item :label="$t('address.country')">
						<el-select v-model="form.areaId" :placeholder="$t('checkout.selectPlease')" style="width: 100%"
							no-data-text="Sin datos">
							<el-option v-for="(val, index) in areaList.region" :key="index" :label="val.name"
								:value="val.areaId"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item :label="$t('address.province')">
						<el-select v-model="form.provinceId" :placeholder="$t('checkout.selectPlease')"
							style="width: 100%" no-data-text="Sin datos">
							<el-option v-for="(val, index) in areaList.province" :key="index" :label="val.name"
								:value="val.areaId"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="8">
					<el-form-item :label="$t('address.city')">
						<el-input v-model="form.cityName"></el-input>
						<!-- <el-select v-model="form.cityId" style="width: 100%">
              <el-option
                v-for="val in areaList.city"
                :label="val.name"
                :value="val.areaId"
              ></el-option>
            </el-select> -->
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<el-form-item :label="$t('address.address')">
						<el-input v-model="form.address"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col :span="12">
					<el-form-item :label="$t('address.postcode')">
						<el-input v-model="form.postcode"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item :label="$t('address.phone')">
						<el-input v-model="form.phone"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<div class="submit" @click="submit">{{ $t("address.save") }}</div>
		<div v-if="showCancel" class="cancel" @click="$emit('cancel')">{{ $t("address.cancel") }}</div>
	</div>
</template>

<script>
	import Api from "../../api/request.js";
	export default {
		name: "AddressForm",
		components: {},
		props: {
			detail: {
				type: Object,
				default: {},
			},
			showCancel: {
				type: Boolean,
				default: true,
			},
			isEdit: {
				type: Boolean,
				default: false,
			},
		},
		watch: {
			"form.areaId": function(res) {
				let findItems = this.areaList.region.find((item) => {
					return item.areaId == res;
				});
				if (findItems) {
					this.areaList.province = findItems.sons || [];
					//选中第一个
					this.form.provinceId = this.areaList.province.length > 0 ? this.areaList.province[0].areaId : "";
				} else {
					this.areaList.province = [];
					this.form.provinceId = "";
				}
			},
			"form.provinceId": function(res) {
				let findItems = this.areaList.province.find((item) => {
					return item.areaId == res;
				});
				if (findItems) {
					this.areaList.city = findItems.sons || [];
				} else {
					this.areaList.city = [];
				}
			},
		},
		data() {
			return {
				areaList: {
					province: [],
					city: [],
					region: [],
				},
				form: {
					provinceId: "",
					areaId: "",
					cityName: "",
					firstName: "",
					secondName: "",
					defaultFlag: 0,
					address: null,
					postcode: "",
					phone: null,
				},
			};
		},
		created() {
			let that = this;
			this.areaList.region = this.$store.state.areaList;
			console.log("create");

			let findItems = that.areaList.region.find((item) => {
				return item.areaId == that.detail.areaId;
			});
			if (findItems) {
				that.areaList.province = findItems.sons || [];
			} else {
				that.areaList.province = [];
			}

			if (this.isEdit) {
				this.form = {
					provinceId: this.detail.provinceId,
					areaId: this.detail.areaId,
					cityName: this.detail.cityName,
					secondName: this.detail.name,
					firstName: this.detail.surname,
					defaultFlag: parseInt(this.detail.defaultFlag),
					address: this.detail.address,
					postcode: this.detail.postcode,
					phone: this.detail.phone,
				};
			}
		},
		methods: {
			submit() {
				this.$dialog.loading();
				var provinve =
					this.areaList.province != null && this.areaList.province.length > 0 ?
					this.areaList.province.find((item) => {
						return item.areaId == this.form.provinceId;
					}) ?
					this.areaList.province.find((item) => {
						return item.areaId == this.form.provinceId;
					}) : {
						name: "",
						areaId: ""
					} : {
						name: "",
						areaId: ""
					};
				var region =
					this.areaList.region != null && this.areaList.region.length > 0 ?
					this.areaList.region.find((item) => {
						return item.areaId == this.form.areaId;
					}) : {
						name: "",
						areaId: ""
					};
				var param = {
					provinceId: provinve.areaId,
					cityName: this.form.cityName,
					areaId: region.areaId,
					surname: this.form.firstName,
					name: this.form.secondName,
					defaultFlag: this.form.defaultFlag,
					address: this.form.address,
					postcode: this.form.postcode,
					phone: this.form.phone,
					// areaJson: [provinve.name,city.name,region.name],
					// company: '',
					// dutyNum: '',
					// dutyType: '',
					// wechat: '',
					// email: '',
					// remark: ''
				};
				if (this.isEdit) {
					param.addressId = this.detail.addressId;
				}
				Api.Address.edit(param).then((res) => {
					if (res.data.status == "SUCCESSS") {
						this.$emit("done");
					}
					this.$dialog.close();
				});
			},
		},
	};
</script>

<style lang="less" scoped>
	.form {
		width: 900px;
		position: relative;

		.cancel {
			height: 48px;
			line-height: 48px;
			position: absolute;
			left: 280px;
			bottom: 0;
			font-size: 14px;
			font-weight: 600;
			color: #123178;
			cursor: pointer;
		}

		.el-form {
			width: 900px;

			.el-row {
				margin-top: 20px;

				&:first-child {
					margin-top: 0;
				}
			}

			.el-form-item {
				margin-bottom: 0;
			}

			/deep/.el-form-item__label {
				font-size: 14px;
				font-weight: 400;
				color: #000000;
				line-height: 20px;
				margin: 0 0 12px;
			}

			/deep/.el-input__inner {
				border-radius: 0;
				border-color: #757575;
			}

			/deep/.el-textarea__inner {
				border-radius: 0;
				border-color: #757575;
				height: 208px;
			}

			/deep/.el-select .el-input .el-select__caret {
				color: #000;
				font-size: 12px;
				font-weight: bolder;
			}
		}
	}

	.submit {
		width: 200px;
		height: 48px;
		background: #123178;
		border-radius: 6px;
		text-align: center;
		line-height: 48px;
		font-size: 14px;
		font-weight: 600;
		color: #ffffff;
		margin-top: 30px;
		cursor: pointer;

		&:hover {
			background-color: #021450;
		}
	}

	.submits {
		display: flex;

		.submit {
			margin-top: 0;
			background: #fff;
			border: 1px solid #123178;
			color: #123178;
			margin-right: 20px;
		}
	}

	@media screen and (max-width: 500px) {
		.form {
			width: 100%;

			.cancel {
				height: 9vw;
				line-height: 9vw;
				position: absolute;
				left: 30vw;
				bottom: 0;
				font-size: 3.5vw;
				font-weight: 600;
				color: #123178;
				cursor: pointer;
			}

			.el-form {
				width: 100%;

				.el-row {
					margin-top: 3vw;
				}

				/deep/.el-form-item__label {
					font-size: 3.5vw;
					margin: 0 0 2vw;
				}

				/deep/.el-input__inner {
					height: 9vw;
				}
			}
		}

		.submit {
			width: 20vw;
			height: 9vw;
			background: #123178;
			border-radius: 6px;
			text-align: center;
			line-height: 9vw;
			font-size: 3.5vw;
			font-weight: 600;
			color: #ffffff;
			margin-top: 6vw;
			cursor: pointer;
		}
	}
</style>
